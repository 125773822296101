<template>
    <div class="list">
        <heads />
        <div class="con-main">
            <div class="hd-navs">
                <a-breadcrumb>
                    <a-breadcrumb-item><a :href="whereGoUrl">{{whereGo}}</a></a-breadcrumb-item>
                    <a-breadcrumb-item>文物列表</a-breadcrumb-item>
                </a-breadcrumb>
            </div>
            <div class="content">
                <div class="emptyData" v-if="list.length === 0">
                    <a-empty description="暂无数据" />
                </div>
                <div v-for="(item, index) in list" :key="index" class="list-item" @click="goDetailPage(item)">
                    <div class="img-box">
                        <div class="i-c">
                            <img :src="item.image" alt="">
                        </div>
                    </div>
                    <div class="t-box">
                        <p class="p-title">{{item.title}}</p>
                        <div v-html="item.infoString" class="t-txts"></div>
                        <div class="xq">[详情]</div>
                    </div>
                </div>
            </div>
            <div class="pages">
                <a-pagination :current="current" :total="all" :defaultPageSize="8" @change="onChange" />
            </div>
        </div>
        <foots />
    </div>
</template>
<script>
import heads from '../component/heads.vue';
import foots from '../component/foot.vue';
export default {
    components: {heads, foots},
    data() {return {
        current: 1,
        all: 0,
        list: [],
        opts: [],
        type: '',
        listAll: [],
        whereGo: '',
        whereGoUrl: './'
    }},
    mounted() {
        this.pageInit(this.current);
    },
    destroyed() {
        this.opts = [];
        this.type = '';
    },
    methods: {
        // 导航
        fnYear() {
            this.whereGo = '藏品鉴赏';
            this.type = 'times';
            this.whereGoUrl = './';
        },
        fnPerson() {
            this.whereGo = '名人遗物';
            this.type = 'person';
            this.whereGoUrl = './?point=2';
        },
        // 初始化
        pageInit(index) {
            if (this.opts.length === 0 || this.type === '') {
                const req = this.$route.query;
                req.type == 'year'? this.fnYear() : this.fnPerson();
                if (req.opt.indexOf('-') != -1 && this.type === 'times') {
                    const arr = req.opt.split('-');
                    if (arr[1] === '') arr[1] = new Date().getFullYear();
                    for(let i = parseInt(arr[0]); i <= parseInt(arr[1]); i++) {
                        this.opts.push(i);
                    }
                } else {
                    this.opts = [req.opt];
                }
            }
            if (this.opts.length === 1) {
                this.getInfo(this.opts[0], index);
            } else {
                if (this.listAll.length === 0) {
                    this.all = 0;
                    this.opts.forEach(item => {
                        this.getInfoTime(item);
                    })
                } else {
                    this.setCurrentList();
                }
            }
        },
        // 获取数据——时间
        getInfoTime(item) {
            this.$axios.get(`/tbnet/tbNet/list?${this.type}=${item}`).then(res => {
                if (res.success && res.result && res.result.records && res.result.records.length > 0) {
                    this.all += res.result.total;
                    const arrs = [];
                    res.result.records.forEach(item => {
                        if (item.pic) item['image'] = `${this.thePageUrl}/sys/common/static/${item.pic.indexOf(',') != -1? item.pic.split(',')[0] : item.pic}`;
                        if (item.infoString.indexOf('<img') != -1) {
                            const a1 = item.infoString.split(' ');
                            const a2 = [];
                            a1.forEach(v => {
                                if (v.indexOf('<img') != -1) {
                                    a2.push(v +=' style="display:none;"');
                                } else {
                                    a2.push(v);
                                }
                            })
                            item.infoString = a2.join(' ');
                        }
                        arrs.push(item);
                    })
                    this.listAll = [...this.listAll, ...arrs];
                    this.pushList();
                }
            })
        },
        // 分页
        setCurrentList() {
            this.list = [];
            console.log('setCurrentList');
            this.listAll.forEach((v, i) => {
                if (i >= (this.current - 1) * 8 && i < this.current * 8) {
                    this.list.push(v);
                }
            })
        },
        // 数据添加到列表
        pushList() {
            if (this.list.length < 8) {
                this.list = [];
                this.listAll.forEach((v, i) => {
                    if (i < 8) {
                        this.list.push(v);
                    }
                })
            }
        },
        // 获取数据
        getInfo(item, index) {
            this.$axios.get(`/tbnet/tbNet/list?${this.type}=${item}&pageNo=${index}&pageSize=8`).then(res => {
                if (res.success && res.result && res.result.records) {
                    this.all = res.result.total;
                    res.result.records.forEach(item => {
                        item['image'] = `${this.thePageUrl}/sys/common/static/${item.pic.indexOf(',') != -1? item.pic.split(',')[0] : item.pic}`;
                    })
                    this.list = res.result.records;
                }
            })
        },
        // 详情页
        goDetailPage(item) {
            window.sessionStorage.setItem('dtlInfo', JSON.stringify(item));
            this.$router.push({path: '/detail', query: {id: item.id, type: this.type}});
        },
        // 分页
        onChange(current) {
            this.current = current;
            this.pageInit(this.current);
        },
    }
}
</script>

<style scoped>
.con-main {
    margin: 0 auto 60px;
    width: 1200px;
    background: #fff;
    box-shadow: 1px 5px 40px rgba(9,2,4,0.1);
    -webkit-box-shadow: 1px 5px 40px rgba(9,2,4,0.1);
    padding: 60px 20px 20px 20px;
}
.content {
    display: flex;
    flex-wrap: wrap;
    min-height: 500px;
}
.list-item {
    width: 49%;
    margin-bottom: 20px;
    margin-right: 2%;
    cursor: pointer;
    transition: all 0.5s;
    display: flex;
    justify-content: space-between;
}
.list-item:nth-child(2n) {
    margin-right: 0;
}
.img-box {
    width: 218px;
    height: 145px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.list-item:hover img {
    transform: scale(1.1);
}
.list-item:hover .p-title {
    color: #990000;
    font-weight: 900;
}
.i-c {
    text-align: center;
}
.list-item img {
    max-width: 218px;
    max-height: 145px;
    transition: transform 1s;
}
.t-box .p-title {
    margin: 10px 0;
    line-height: 1.5;
    font-size: 16px;
    color: #000;
    margin-bottom: 0.5em;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: keep-all;
    overflow: hidden;
}
.pages {
    text-align: center;
    margin-bottom: 30px;
    margin-top: 30px;
}
/deep/ .ant-pagination-item-active {
    border-color: #990000;
}
/deep/ .ant-pagination-item-active a {
    color: #990000;
}
.hd-navs {
        width: 1200px;
        margin: 30px auto;
    }
    .hd-navs /deep/ .ant-breadcrumb-link a, .hd-navs /deep/ .ant-breadcrumb-link {
        font-size: 20px;
        font-weight: 600;
        color: #000;
    }
    .emptyData {
        margin: 0 auto;
    }

.t-box {
    width: 60%;
}
.t-txts {
display: -moz-box;
display: -webkit-box;
-moz-box-orient: vertical;
-webkit-box-orient: vertical;
-webkit-line-clamp: 3;
overflow: hidden;
height: 62px;
}
.t-txts p {margin-bottom: 0px!important;}
.xq {
    color: #990000;
    text-align: right;
    margin-top: 10px;
}
</style>